
.chat-header{
    font-family: Ubuntu;
    color: #fff;
    background-color:#10a19f;
    margin: 0;
    padding: 10px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}

.chat-outer-wrapper{
    flex-grow: 2;
    overflow: auto;
}
.chat-wrapper{
    margin: 5px;
    background-color: #333;
    border-radius: 12px;
    padding: 5px;
    width:80%;
    direction: ltr;
    align-self: flex-start;
}
.outline-btn{
    background-color: transparent;
    outline: none;
    border: none;
}

.chat-wrapper-user{
    align-self: flex-end;
}
.chat-member-name{
    font-weight: 700;
    color:#086d6b;
    text-align: left;
}
.chat-message{
    color: rgb(255, 255, 255);
    text-align: left;
}
.chat-pro-sidebar-footer{
    display: flex;
    padding: 10px;
    justify-content:space-evenly;
    
}
.chatEntry{
    outline:none;
    border:none;
    text-align: left;
    background-color: transparent;
    color: white;
}
.chat-pro-sidebar-content
{
    overflow: auto;
    display: flex;
    flex-direction: column;
    direction: ltr;
}
.chat-opener{
    color: #10a19f;
    width: 40px;
    height:40px;

}
.chat-opener:hover
{
    cursor: pointer;
}
.chat-badge{
    position:absolute;
    right: 15px;
    top: 15px;
    width: 15px !important;;
    height: 15px !important;
    border-radius:50%;
    background-color:#65d4d3;
}
.chat-pro-sidebar{
    text-align:left!important;
    padding-bottom: 50px;
    margin-bottom: 10px;
    bottom: 0;
}
@media screen and (max-width:660px)
{
    .chat-section{
        width:100%;
    }
    .chat-pro-sidebar{
        position: absolute !important;
        right: 0 !important;
        text-align:left!important;
        
    }
}