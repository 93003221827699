/**=====================
 61. Login CSS Start
 ==========================**/
.show-hide {
    position: absolute;
    top: 18px;
    right: 30px;
    transform: translateY(-50%);
    text-transform: capitalize;
    z-index: 2;
}
.show-hide span {
    cursor: pointer;
    font-size: 13px;
}
.show-hide span .show-hide span:before {
    content: "hide";
}
.show-hide span.show:before {
    content: "show";
}
.needs-validation .invalid-tooltip {
    top: -35px;
    right: 10px;
}
.login-card {
    background-color: rgba(77, 138, 255, 0.05);
    height: 100vh;
    display: flex !important;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    margin: 0 auto;
    padding: 30px 12px;
}
.login-form {
    padding: 30px;
    width: 450px;
    background-color: #fff;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}
.login-form h4 {
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 5px;
    font-size: 30px;
}
.login-form h6 {
    font-size: 14px;
    margin-bottom: 25px;
    color: rgba(27, 49, 85, 0.4);
}
.login-form .btn {
    text-transform: uppercase;
    font-weight: 700;
    margin-left: auto;
    display: block;
}
.login-form p {
    font-weight: 600;
    text-align: center;
    margin-bottom: 0;
}
.login-form .form-group {
    position: relative;
}
.login-form .form-group label {
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 5px;
}
.login-form .form-group .input-group-text {
    background-color: rgba(205, 222, 255, 0.1);
    border: none;
}
.login-form .form-group input {
    transition: all 0.3s ease;
}
.login-form .form-group .form-control::placeholder {
    font-weight: 400;
}
.login-form .form-group .form-control:focus {
    box-shadow: none;
}
.login-form .form-group .checkbox {
    display: inline-block;
}
.login-form .form-group .checkbox label {
    padding-top: 0;
    margin-top: 0;
    color: #aaaaaa;
}
.login-form .form-group .checkbox label span {
    color: rgba(205, 222, 255, 0.5);
}
.login-form .form-group .link {
    float: right;
    font-weight: 500;
}
.login-form .small-group {
    display: flex;
    align-items: center;
}
.login-form .small-group .input-group:nth-child(n + 2) {
    margin-left: 10px;
}
.login-form ul.login-social {
    display: flex;
    align-items: center;
    justify-content: center;
}
.login-form ul.login-social li {
    display: inline-block;
    width: 35px;
    height: 35px;
    background-color: rgba(205, 222, 255, 0.08);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}
.login-form ul.login-social li a {
    line-height: 1;
}
.login-form ul.login-social li a svg {
    width: 16px;
    height: auto;
}
.login-form ul.login-social li:nth-child(n + 2) {
    margin-left: 10px;
}
.login-form .login-social-title {
    position: relative;
    margin-bottom: 20px;
    z-index: 2;
}
.login-form .login-social-title::before {
    content: "";
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #efefef;
    z-index: -1;
}
.login-form .login-social-title h5 {
    font-weight: 600;
    font-size: 16px;
    color: rgba(27, 49, 85, 0.4);
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #fff;
}
@media (max-width: 480px) {
    .login-form {
        width: 100%;
        padding: 20px;
    }
    .login-form h4 {
        font-size: 25px;
    }
    .login-form h6 {
        font-size: 13px;
        margin-bottom: 15px;
    }
    .login-form .login-social-title h5 {
        font-size: 13px;
    }
    .login-form ul.login-social li {
        width: 30px;
        height: 30px;
    }
    .login-form ul.login-social li a svg {
        width: 14px;
    }
    .login-form .link {
        float: unset;
        display: block;
    }
    .login-form .small-group {
        display: block;
    }
    .login-form .small-group .input-group:nth-child(n + 2) {
        margin-left: unset;
        margin-top: 15px;
    }
}
@media (max-width: 420px) {
    .login-form h4 {
        font-size: 22px;
    }
}
/**==========================
 61. Login CSS Ends
 ==========================**/
.btn-primary{background-color:#4d8aff !important;border-color:#4d8aff !important}.btn-primary.disabled,.btn-primary:disabled{background-color:#4d8aff !important;border-color:#4d8aff !important}.btn-primary:hover,.btn-primary:focus,.btn-primary:active,.btn-primary.active{background-color:#1a68ff !important;border-color:#1a68ff !important}.btn-primary:focus{-webkit-box-shadow:0 0 0 0.2rem #e6efff;box-shadow:0 0 0 0.2rem #e6efff}
.btn-block{border-top-left-radius:0;border-top-right-radius:0}
.form-group{margin-bottom:10px}