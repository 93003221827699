.user-header{
    color: #000000;
    background-color:rgba(255, 255, 255, 1);
    padding: 10px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    border-bottom: 1px solid lightgrey;
    height: 50px;
    align-items: center;
    vertical-align: middle;
}

.outline-btn{
    background-color: transparent;
    outline: none;
    border: none;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

ul li {
    overflow: hidden;
    margin-left: 10px;
    margin-right: 10px;
    height: 40px;
    vertical-align: center;
    border-bottom: 1px solid #FFFFFF;
}

.user-pro-sidebar{
    text-align:left!important;
    padding-bottom: 60px;
    margin-bottom: 10px;
    bottom: 0;
}

.user-control{
    color: #000000;
    font-size: 1.8em;
}

.user-pro-sidebar-content
{
    overflow: auto;
    display: flex;
    flex-direction: column;
    direction: ltr;
    color: #000000;
    background-color:rgba(255, 255, 255, 1);
}
@media screen and (max-width:660px)
{
    .user-pro-sidebar{
        position: absolute !important;
        right: 0 !important;
        text-align:left!important;

    }
}