* {
    outline: none;
    padding: 0;
}
*::after {
    margin: 0;
    padding: 0;
}
*::before {
    margin: 0;
    padding: 0;
}

body {
    line-height: 1.75;
}
body.fixed {
    overflow: hidden;
    position: fixed;
}
body#landing {
    background: #fff;
}

ul {
    padding: 0;
    margin: 0;
}

li {
    list-style: none;
}

a {
    text-decoration: none;
    outline: none;
}
a:hover,
a:focus,
a.active {
    text-decoration: none;
    outline: none;
    color: #5d78ff;
}

b,
strong,
.strong {
    font-weight: 500;
    color: #48465b;
}

.h-0 {
    height: 0;
}

#main-wrapper {
    transition: all 0.25s ease-in;
    position: relative;
    overflow: hidden;
    z-index: 1;
    margin-top: 60px;
}
#main-wrapper.show {
    opacity: 1;
}

.content-body {
    margin-left: 100px;
    margin-top: 18px;
    margin-bottom: 45px;
}

#preloader {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #f2f3f8;
    z-index: 999999999;
}

.sk-three-bounce {
    margin: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: #f2f3f8;
}

.sk-three-bounce .sk-child {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background-color: #5d78ff;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
    animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
}

.sk-three-bounce .sk-bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.sk-three-bounce .sk-bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-three-bounce {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes sk-three-bounce {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.mw-150 {
    max-width: 150px;
}

.fs-18 {
    font-size: 18px;
}

.fs-19 {
    font-size: 19px;
}

.fs-20 {
    font-size: 20px;
}

.section-padding {
    padding: 100px 0px;
}
@media only screen and (max-width: 1199px) {
    .section-padding {
        padding: 70px 0px;
    }
}

.section-title {
    margin-bottom: 75px;
}
.section-title h2,
.section-title .h2 {
    text-align: center;
    font-weight: 500;
}

#toast-container canvas {
    position: absolute;
    z-index: 999;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
}
#toast-container .toast-message {
    max-width: 300px;
}
#toast-container .progress_count {
    position: absolute;
    color: #282a3c;
    font-size: 18px;
    left: 36px;
    text-align: center;
    right: 0;
    width: 50px;
    height: 100%;
    top: 50px;
}
#toast-container .la-close {
    position: absolute;
    right: 20px;
    top: 15px;
    width: 24px;
    font-size: 24px;
}
#toast-container a {
    display: inline-block;
    width: 80px;
    padding: 4px;
    text-align: center;
    position: absolute;
    bottom: 15px;
    right: 20px;
    background: #ffdeb0;
    border-radius: 30px;
    color: #fe9431;
}

.toast-progress {
    background: #fe9431;
    opacity: 6;
}

.p2col {
    column-count: 2;
    column-gap: 50px;
}
@media only screen and (max-width: 767px) {
    .p2col {
        column-count: 1;
    }
}

::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #777;
}

.thumb-style {
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    background: #5d78ff;
    border: 1px solid #2a4eff;
    border-radius: 100%;
    cursor: pointer;
    z-index: 5000;
    -webkit-transition: background 0.3s ease-in-out;
    transition: background 0.3s ease-in-out;
}

.thumb-style_hover {
    background: #2a4eff;
    -webkit-transition: background 0.3s ease-in-out;
    transition: background 0.3s ease-in-out;
}

.slider-container {
    display: inline-block;
    position: relative;
    width: 100%;
    margin: 15px 0 20px;
}

input[type="range"] {
    position: relative;
    z-index: 1000;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    height: 15px;
    border-radius: 3px;
    outline: none;
    margin: 0;
    padding: 0;
    width: 100%;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.9);
}
input[type="range"]::-webkit-slider-thumb {
    position: relative;
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: #5d78ff;
    border: 1px solid #2a4eff;
    border-radius: 100%;
    cursor: pointer;
    z-index: 5000;
    -webkit-transition: background 0.3s ease-in-out;
    transition: background 0.3s ease-in-out;
}
input[type="range"]::-webkit-slider-thumb:hover,
input[type="range"]::-webkit-slider-thumb:active {
    background: #2a4eff;
    -webkit-transition: background 0.3s ease-in-out;
    transition: background 0.3s ease-in-out;
}
input[type="range"]::-moz-range-track {
    background: transparent;
}
input[type="range"]::-moz-range-thumb {
    position: relative;
    -moz-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: #5d78ff;
    border: 1px solid #2a4eff;
    border-radius: 100%;
    cursor: pointer;
    z-index: 5000;
    -moz-transition: background 0.3s ease-in-out;
    transition: background 0.3s ease-in-out;
}
input[type="range"]::-moz-range-thumb:hover,
input[type="range"]::-moz-range-thumb:active {
    background: #2a4eff;
    -moz-transition: background 0.3s ease-in-out;
    transition: background 0.3s ease-in-out;
}

.fill {
    position: absolute;
    z-index: 50;
    top: 2px;
    height: 15px;
    width: 0%;
    border-radius: 3px;
    background: -webkit-gradient(
            linear,
            left top,
            right top,
            from(#d6e6fa),
            to(#2a4eff)
    );
    background: linear-gradient(to right, #d6e6fa 0%, #2a4eff 100%);
}

.count {
    position: absolute;
    top: -25px;
    left: 0;
    border: 1px solid #2a4eff;
    background: #5d78ff;
    color: #fff;
    padding: 2px 5px;
    border-radius: 20px;
    text-align: center;
    margin-left: 0px;
    font-size: 11px;
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .col-xxl-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }
    .col-xxl-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .col-xxl-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-xxl-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .col-xxl-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
    .col-xxl-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-xxl-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
    .col-xxl-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    .col-xxl-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-xxl-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
    .col-xxl-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }
    .col-xxl-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.card {
    border: 0px solid #eff0f6;
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.05);
    background: white;
}
.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eff0f6;
    background: white;
    padding: 15px 20px;
}
.card-title {
    font-size: 16px;
    margin-bottom: 0px;
}
.card .card-body {
    padding: 15px 20px;
    background: white;
    border-radius: 15px;
}

.mb-3 {
    position: relative;
}
.mb-3 label {
    font-size: 14px;
    font-weight: 500;
    color: #74788d;
    margin-bottom: 10px;
    background: transparent;
    border-color: #eff0f6;
}

.form-control {
    border-radius: 4px;
    height: 40px;
    border: 1px solid #eff0f6;
    padding: 0px 15px;
    font-size: 16px;
    font-weight: 400;
    color: #74788d;
    transition: all 0.3s ease-in-out;
    background: #f2f3f8;
}
.form-control .select {
    padding: 12px 0;
}
.form-control span {
    margin-top: 0;
}
.form-control::-webkit-input-placeholder {
    color: #74788d;
}
.form-control:-ms-input-placeholder {
    color: #74788d;
}
.form-control::placeholder {
    color: #74788d;
}
.form-control:hover {
    box-shadow: none !important;
    outline: none;
    border-color: #eff0f6;
    color: #74788d;
    background: #f2f3f8;
}
.form-control:focus,
.form-control:active,
.form-control.active {
    box-shadow: none !important;
    outline: none;
    border-color: #5d78ff;
    color: #74788d;
    background: #f2f3f8;
}

.input-group-text {
    padding: 5px 15px;
    margin-bottom: 0px !important;
    color: #74788d;
    border-color: #eff0f6;
}

.input-group-append .input-group-text {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.input-group-prepend .input-group-text {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

/*Styling Selectbox*/
.toggle {
    cursor: pointer;
    display: block;
}

.toggle-switch {
    display: inline-block;
    background: #e8eaf3;
    border-radius: 16px;
    width: 45px;
    height: 20px;
    position: relative;
    vertical-align: middle;
    transition: background 0.25s;
}
.toggle-switch:before,
.toggle-switch:after {
    content: "";
}
.toggle-switch:before {
    display: block;
    background: linear-gradient(to bottom, #fff 0%, #eee 100%);
    border-radius: 50%;
    width: 17px;
    height: 17px;
    position: absolute;
    top: 1px;
    left: 3px;
    transition: left 0.25s;
}
.toggle:hover .toggle-switch:before {
    background: linear-gradient(to bottom, #fff 0%, #fff 100%);
}
.toggle-checkbox:checked + .toggle-switch {
    background: #5d78ff;
}
.toggle-checkbox:checked + .toggle-switch:before {
    left: 26px;
}

.toggle-checkbox {
    position: absolute;
    visibility: hidden;
}

.toggle-label {
    margin-left: 15px;
    position: relative;
    top: 2px;
    font-size: 16px;
    font-weight: 400;
}

.file-upload-wrapper {
    position: relative;
    width: 100%;
    height: calc(1.5em + 1rem + 2px);
    border: 1px solid #eff0f6;
    border-radius: 5px;
    color: #aeaed5;
}
.file-upload-wrapper:after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    background: #f2f3f8;
    padding: 4px 15px;
    display: block;
    width: calc(100% - 40px);
    pointer-events: none;
    z-index: 20;
    height: calc(1.5em + 1rem + 2px);
    line-height: 30px;
    border-radius: 5px 10px 10px 5px;
    font-weight: 400;
    overflow: hidden;
}
.file-upload-wrapper:before {
    content: "Upload";
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    height: calc(1.5em + 1rem + 2px);
    background: #5d78ff;
    color: #fff;
    font-weight: 400;
    z-index: 25;
    font-size: 14px;
    line-height: 40px;
    padding: 0 15px;
    text-transform: capitalize;
    pointer-events: none;
    border-radius: 0 5px 5px 0;
}
.file-upload-wrapper:hover:before {
    background: #2a4eff;
}
.file-upload-wrapper input {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    height: calc(1.5em + 1rem + 2px);
    margin: 0;
    padding: 0;
    display: block;
    cursor: pointer;
    width: 100%;
}

#ui-datepicker-div {
    display: none;
    background: #ebedf4;
    box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.1);
    margin-top: 0.25rem;
    border-radius: 0.5rem;
    padding: 0.5rem;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

.ui-datepicker-calendar thead th {
    padding: 0.25rem 0;
    text-align: center;
    font-size: 0.75rem;
    font-weight: 400;
    color: #74788d;
}

.ui-datepicker-calendar tbody td {
    width: 2.5rem;
    text-align: center;
    padding: 0;
}
.ui-datepicker-calendar tbody td a {
    display: block;
    border-radius: 0.25rem;
    line-height: 2rem;
    transition: 0.3s all;
    color: #74788d;
    font-size: 0.875rem;
    text-decoration: none;
}
.ui-datepicker-calendar tbody td a:hover {
    background-color: #5d78ff;
    color: white;
}
.ui-datepicker-calendar tbody td a.ui-state-active {
    background-color: #5d78ff;
    color: white;
}

.ui-datepicker-header a.ui-corner-all {
    cursor: pointer;
    position: absolute;
    top: 0;
    width: 2rem;
    height: 2rem;
    margin: 0.5rem;
    border-radius: 0.25rem;
    transition: 0.3s all;
}
.ui-datepicker-header a.ui-corner-all:hover {
    background-color: #f6f8ff;
    color: #fff;
}

.ui-datepicker-header a.ui-datepicker-prev {
    left: 0;
    background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==");
    background-repeat: no-repeat;
    background-size: 0.5rem;
    background-position: 50%;
    transform: rotate(180deg);
}

.ui-datepicker-header a.ui-datepicker-next {
    right: 0;
    background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==");
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: 50%;
}

.ui-datepicker-header a > span {
    display: none;
}

.ui-datepicker-title {
    text-align: center;
    line-height: 2rem;
    margin-bottom: 0.25rem;
    font-size: 0.875rem;
    font-weight: 500;
    padding-bottom: 0.25rem;
}

.ui-datepicker-week-col {
    color: #74788d;
    font-weight: 400;
    font-size: 0.75rem;
}

label.error {
    color: #e50202;
    position: absolute;
    bottom: 0;
    margin-bottom: -22px;
    font-size: 12px;
    font-weight: 400;
}

.nice-select {
    display: flex;
    align-items: center;
    border-top-left-radius: 3px !important;
    border-bottom-left-radius: 3px !important;
}
.nice-select ul {
    width: 100% !important;
}

.nice-select .list {
    background: #fff;
}

.nice-select .option:hover,
.nice-select .option.focus,
.nice-select .option.selected.focus {
    color: #5d78ff;
}

.table-responsive-sm {
    min-width: 48rem;
}

.table {
    margin-bottom: 0px;
    font-weight: 400;
}
.table tr td,
.table tr th {
    border: 0px;
    vertical-align: middle;
    padding: 15px;
}
.table tr td:last-child,
.table tr th:last-child {
    text-align: right;
}

#dashboard .table tr td,
#dashboard .table tr th {
    padding: 5px 7px;
}

.table tr th {
    padding: 0px 5px;
    color: #48465b;
    font-weight: 500;
}

.table-striped thead tr th {
    padding-bottom: 15px !important;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #f6f8ff;
}

.table-striped tfoot {
    border-top: 2px solid #eff0f6;
}
.table-striped tfoot tr th {
    padding-top: 10px;
}

.scroll-wrapper,
.market-limit,
.order-book,
.trade-history,
.depth-chart,
.open-orders .buy-sell-limit {
    position: relative;
    height: 400px;
    margin-bottom: 10px;
}

.price-pair,
.balance-widget,
.market-news,
.your-position {
    position: relative;
    height: 400px;
    margin-bottom: 10px;
}

.limit-nested {
    position: relative;
    height: 210px;
}

.market-nested {
    position: relative;
    height: 270px;
}

.stop-limit-nested {
    position: relative;
    height: 165px;
}

.open-position-table {
    position: relative;
    height: 400px;
    margin-bottom: 10px;
}

.card-header ul li {
    line-height: initial;
}
.card-header ul li a {
    padding: 0px 7px;
    color: #74788d;
    font-weight: 500;
}
.card-header ul li a.active {
    background-color: transparent !important;
    color: #5d78ff !important;
    opacity: 1;
}

.order-book-divider {
    text-align: center;
    padding: 15px 0px;
    font-family: "Barlow", sans-serif;
}
.order-book-divider h6,
.order-book-divider .h6 {
    margin-bottom: 0px;
}

#chartdiv {
    width: 100%;
    height: 380px;
}

.duration-option {
    box-shadow: 0px 36px 48px rgba(31, 66, 135, 0.04);
}
.duration-option a {
    display: inline-block;
    margin-left: 10px;
    text-align: center;
    padding: 5px 15px;
    border: 1px solid #eff0f6;
    transition: all 0.3s ease;
    font-weight: 500;
    border-radius: 5px;
}
.duration-option a :hover,
.duration-option a:focus,
.duration-option a.active {
    background: #5d78ff;
    color: #fff;
    border-right: 1px solid transparent;
}

.market-news img {
    border-radius: 5px;
}

.total-balance {
    padding: 10px 0px 20px;
    text-align: center;
}

.open-position-table table tr th,
.open-position-table table tr td {
    padding: 10px 10px !important;
}

.intro {
    padding: 100px 0px;
    border-bottom: 1px solid #eff0f6;
}
.intro .intro-content h1,
.intro .intro-content .h1 {
    line-height: 55px;
    margin-bottom: 30px;
}
.intro .intro-content p {
    font-size: 20px;
    margin-bottom: 50px;
}

.intro-form {
    position: relative;
    max-width: 450px;
    margin: 0 auto 50px;
}
.intro-form form {
    display: flex;
}
.intro-form button {
    position: relative;
    background: #5d78ff;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    padding: 7px 20px;
    color: #fff;
    font-size: 24px;
    overflow: hidden;
    width: 60px;
    min-width: 60px;
    border: 0px;
    margin-left: 15px;
}
.intro-form button .first {
    position: absolute;
    right: 17px;
    top: 12px;
    z-index: 1;
    transition: all 0.2s ease-in-out;
}
.intro-form button .second {
    position: absolute;
    left: -22px;
    top: 12px;
    z-index: 1;
    transition: all 0.2s ease-in-out;
}
.intro-form button:hover,
.intro-form button:focus {
    outline: none;
}
.intro-form button:hover .first,
.intro-form button:focus .first {
    right: -22px;
}
.intro-form button:hover .second,
.intro-form button:focus .second {
    left: 20px;
}

@media only screen and (max-width: 991px) {
    .intro-btn {
        margin-bottom: 50px;
    }
}

.intro-btn .btn-primary {
    padding: 10px 30px;
    margin-right: 30px;
    font-weight: 500;
    font-size: 16px;
}

.intro-btn .btn-outline-primary {
    padding: 10px 30px;
    font-weight: 500;
    font-size: 16px;
}

.intro-form-exchange {
    padding: 30px;
    box-shadow: 0px 20px 25px rgba(22, 28, 45, 0.05);
    border-radius: 5px;
    background: #fff;
}
.intro-form-exchange .nice-select {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}
.intro-form-exchange .btn {
    padding: 10px 15px;
    font-weight: 500;
    position: relative;
    font-size: 16px;
}
.intro-form-exchange .btn i {
    font-size: 22px;
    font-weight: bold;
    position: absolute;
    right: 15px;
}

.sponsor {
    border-top: 1px solid #eff0f6;
    border-bottom: 1px solid #eff0f6;
}

.sponsor-logo {
    display: flex !important;
    justify-content: space-between;
}
.sponsor-logo a {
    display: block;
    margin-bottom: 50px;
}

.trusted-logo {
    text-align: center;
    margin-bottom: 30px;
}
.trusted-logo a {
    display: inline-block;
}

.features {
    background: #fff;
}

.features-tab .nav {
    justify-content: center;
    margin-bottom: 50px;
}
.features-tab .nav .nav-item {
    border-bottom: 1px solid #ddd;
}
.features-tab .nav .nav-item .nav-link {
    color: #48465b;
    font-weight: 500;
    padding: 10px 30px;
    background: transparent;
    border-radius: 0px;
    margin-bottom: -1px;
}
.features-tab .nav .nav-item .nav-link.active {
    background: transparent;
    color: #5d78ff;
    border-bottom: 1px solid #5d78ff;
}

.features-content-img img {
    max-width: 80%;
}

.features-content-text h3,
.features-content-text .h3 {
    margin-bottom: 20px;
}

.features-content-text p {
    font-weight: 300;
    line-height: 30px;
}

.get-touch {
    border-bottom: 1px solid #eff0f6;
}

.get-touch-content {
    padding: 30px;
    margin-bottom: 30px;
    box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.1);
    background: #fff;
    border-radius: 5px;
}
.get-touch-content span {
    margin-right: 15px;
}
.get-touch-content span i {
    color: #5d78ff;
    font-size: 42px;
}
.get-touch-content h4,
.get-touch-content .h4 {
    font-size: 20px;
    margin-bottom: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.get-touch-content p {
    line-height: 27px;
}

.testimonial-content {
    background: #fff;
    box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.05);
    border-radius: 5px;
}
.testimonial-content .owl-carousel .owl-nav .owl-prev {
    position: absolute;
    left: -29px;
    top: 50%;
    transform: translateY(-50%);
    width: 50px;
    height: 50px;
    min-width: auto;
    border-radius: 50px;
    background: #5d78ff;
    color: #fff;
    font-size: 18px;
}
.testimonial-content .owl-carousel .owl-nav .owl-prev:hover,
.testimonial-content .owl-carousel .owl-nav .owl-prev:focus {
    outline: none;
}
.testimonial-content .owl-carousel .owl-nav .owl-next {
    position: absolute;
    right: -29px;
    top: 50%;
    transform: translateY(-50%);
    width: 50px;
    height: 50px;
    min-width: auto;
    border-radius: 50px;
    background: #5d78ff;
    color: #fff;
    font-size: 18px;
}
.testimonial-content .owl-carousel .owl-nav .owl-next:hover,
.testimonial-content .owl-carousel .owl-nav .owl-next:focus {
    outline: none;
}

.customer-img img {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    position: relative;
}
.customer-img img::before {
    position: absolute;
    content: "";
}

.customer-review {
    padding: 30px;
}
.customer-review img {
    width: auto !important;
    margin-bottom: 30px;
    filter: brightness(100);
}
.customer-review p {
    margin-bottom: 0px;
}

.customer-info {
    margin-top: 20px;
}
.customer-info h6,
.customer-info .h6 {
    font-size: 18px;
}

@media only screen and (max-width: 991px) {
    .appss-content {
        margin-top: 50px;
        margin-bottom: 50px;
    }
}

.appss-content h2,
.appss-content .h2 {
    margin-bottom: 30px;
    font-weight: 500;
}

.appss-content ul li {
    padding: 10px 0;
    font-size: 17px;
}
.appss-content ul li i {
    font-weight: bold;
    font-size: 18px;
    color: #5d78ff;
    margin-right: 15px;
}

.appss-content .btn {
    display: inline-block;
    padding: 10px 30px;
    border-radius: 50px;
    margin-right: 15px;
}

.whatsNew-tab .nav {
    flex-direction: column;
}
.whatsNew-tab .nav .nav-item {
    margin-bottom: 30px;
}
.whatsNew-tab .nav .nav-item .nav-link {
    background: transparent;
    color: #48465b;
    font-size: 20px;
    padding: 15px 15px;
    border: 1px solid #5d78ff;
    transition: all 0.5s ease-in-out;
}
.whatsNew-tab .nav .nav-item .nav-link:hover,
.whatsNew-tab .nav .nav-item .nav-link:focus,
.whatsNew-tab .nav .nav-item .nav-link.active {
    background: white;
    color: #5d78ff;
    border: 1px solid #5d78ff;
}

.accordion {
    box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.1);
}
.accordion__item {
    margin-bottom: -1px;
}
.accordion__header {
    padding: 0.9375rem;
    border: 1px solid #eff0f6;
    cursor: pointer;
    position: relative;
    font-weight: 500;
}
.accordion__header--indicator {
    font-family: "LineAwesome";
    position: absolute;
    right: 1.5625rem;
    top: 50%;
    transform: translateY(-50%);
}
.accordion__header--indicator.indicator_bordered {
    display: inline-block;
    width: 25px;
    text-align: center;
    height: 25px;
    border: 1px solid #ddd;
    border-radius: 50%;
    line-height: 25px;
}
.accordion__header:not(.collapsed) .accordion__header--indicator::before {
    content: "\f110";
}
.accordion__header:not(.collapsed)
.accordion__header--indicator.style_two::before {
    content: "\f113";
}
.accordion__header.collapsed .accordion__header--indicator::before {
    content: "\f113";
}
.accordion__header.collapsed .accordion__header--indicator.style_two::before {
    content: "\f110";
}
.accordion__body--text {
    padding: 0.9375rem;
}
.accordion-active-header .accordion__header:not(.collapsed) {
    background-color: #5d78ff;
    color: #fff;
}

.getstart-content {
    text-align: center;
}
.getstart-content i {
    font-size: 48px;
    color: #5d78ff;
}
.getstart-content h3,
.getstart-content .h3 {
    margin-top: 15px;
    font-size: 20px;
}

.promo {
    border-bottom: 1px solid #eff0f6;
}
.promo .promo-content {
    text-align: center;
}
.promo .promo-content .promo-content-img {
    background: #5d78ff;
    height: 150px;
    width: 150px;
    border-radius: 15px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 30px;
    box-shadow: rgba(218, 225, 233, 0.557) 0px 8px 16px;
}
.promo .promo-content .promo-content-img img {
    max-width: 70px;
}
.promo .promo-content h3,
.promo .promo-content .h3 {
    margin-bottom: 15px;
}
.promo .promo-content p {
    line-height: 30px;
}

.portfolio_list .d-flex {
    margin-bottom: 30px;
}
.portfolio_list .d-flex h4,
.portfolio_list .d-flex .h4 {
    font-size: 22px;
}
.portfolio_list .d-flex .port-icon {
    height: 65px;
    width: 140px;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 8px 16px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    margin-right: 20px;
    color: #5d78ff;
}
.portfolio_list .d-flex p {
    margin-bottom: 0px;
}
.portfolio_list .d-flex p a {
    color: #5d78ff;
}

.portfolio_img img {
    border-radius: 5px;
    box-shadow: 25px 60px 125px -25px rgba(0, 0, 0, 0.2),
    16px 40px 75px -40px rgba(0, 0, 0, 0.2);
}

.trade-app-content {
    border: 0px;
    background: #fff;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.1);
}
.trade-app-content .card-body {
    padding: 20px 30px;
}
.trade-app-content .card-body span {
    font-size: 48px;
    margin-bottom: 15px;
    display: inline-block;
    color: #5d78ff;
}
.trade-app-content .card-body h4,
.trade-app-content .card-body .h4 {
    font-size: 20px;
    margin-bottom: 15px;
}
.trade-app-content .card-body p {
    min-height: 115px;
}
@media only screen and (max-width: 1199px) {
    .trade-app-content .card-body p {
        min-height: 165px;
    }
}
@media only screen and (max-width: 991px) {
    .trade-app-content .card-body p {
        min-height: auto;
    }
}
.trade-app-content .card-body a {
    color: #5d78ff;
    display: flex;
    font-weight: 500;
    align-items: center;
}
.trade-app-content .card-body a i {
    margin-left: 15px;
    font-size: 18px;
    font-weight: bold;
}

.our_vision h3,
.our_vision .h3 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 28px;
}

.our_vision p {
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 30px;
}

.service-img img {
    border-radius: 5px;
}

.profile_card .media {
    border-bottom: 1px solid #eff0f6;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.card-profile__info h5,
.card-profile__info .h5 {
    display: inline-block;
}

.social-icons {
    margin-top: 20px;
}
.social-icons a {
    border-radius: 100px;
    padding: 7px 0px;
    margin-right: 15px;
    height: 42px;
    width: 42px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: #5d78ff;
    text-align: center;
}

.acc_balance .btn-group {
    width: 100%;
}

.linked_account .media {
    align-items: center;
    border-radius: 5px;
}
.linked_account .media > span {
    background: #5d78ff;
    color: #fff;
    padding: 10px;
    border-radius: 50px;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
}
.linked_account .media .media-body p {
    margin-bottom: 0px;
}

.linked_account .edit-option a {
    display: inline-block;
    padding: 5px;
    font-size: 18px;
}
.linked_account .edit-option a:last-child {
    color: #e50202;
}

.linked_account .verify .verified,
.linked_account .verify .not-verify {
    display: flex;
    align-items: center;
    font-weight: 500;
}
.linked_account .verify .verified span,
.linked_account .verify .not-verify span {
    background: #10d876;
    color: #fff;
    padding: 10px;
    border-radius: 50px;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    margin-right: 15px;
    font-weight: bold;
}

.linked_account .verify .not-verify span {
    background: #e50202;
}

#deposits .nav-tabs li a {
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    font-size: 16px;
    font-weight: 500;
    color: #74788d;
}
#deposits .nav-tabs li a.active {
    background: #fff;
    color: #5d78ff;
    border-bottom: 1px solid #5d78ff;
}

#deposits .tab-content .qrcode {
    text-align: center;
    margin-top: 50px;
    padding: 20px 0;
}

#deposits .tab-content form {
    max-width: 750px;
    margin: 30px auto 50px;
}

#deposits .tab-content ul {
    max-width: 750px;
}
#deposits .tab-content ul li {
    padding-left: 25px;
    position: relative;
    padding-bottom: 7px;
}
#deposits .tab-content ul li i {
    font-size: 16px;
    margin-right: 7px;
    position: absolute;
    left: 0;
}

.important-info ul {
    max-width: 850px;
}
.important-info ul li {
    padding-left: 25px;
    position: relative;
    padding-bottom: 7px;
}
.important-info ul li i {
    font-size: 16px;
    margin-right: 7px;
    position: absolute;
    left: 0;
}

.authincation {
    height: 100%;
}

.auth-form {
    border: 1px solid #eff0f6;
}
.auth-form .page-back {
    display: inline-block;
    margin-bottom: 15px;
}

.verification {
    height: 100%;
}

.identity-content {
    text-align: center;
}
.identity-content .icon {
    display: flex;
    width: 75px;
    height: 75px;
    border-radius: 100px;
    margin: 0 auto;
    background: #10d876;
    color: #097942;
    font-size: 30px;
    padding: 10px;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}
.identity-content h4,
.identity-content .h4 {
    max-width: 350px;
    margin: 0 auto 15px;
}
.identity-content p {
    max-width: 350px;
    margin: 0 auto 30px;
}

.blog {
    border-bottom: 1px solid #eff0f6;
    border-top: 1px solid #eff0f6;
}

.blog-grid .card {
    border: 0px;
    box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.1);
    margin-bottom: 30px;
    background: #fff;
}
.blog-grid .card img {
    max-height: 185px;
    width: 100%;
}
@media only screen and (max-width: 1199px) {
    .blog-grid .card img {
        max-height: 150px;
    }
}
@media only screen and (max-width: 991px) {
    .blog-grid .card img {
        max-height: 100%;
    }
}
.blog-grid .card .card-body {
    padding: 20px !important;
}
.blog-grid .card .card-body .card-title {
    font-size: 20px;
    margin-bottom: 15px;
}
.blog-grid .card .card-footer {
    padding: 10px 30px 30px;
    background: #fff;
    border: 0px;
}
.blog-grid .card .card-footer .meta-info {
    display: flex;
    justify-content: space-between;
}
.blog-grid .card .card-footer .meta-info .author img {
    max-width: 30px;
    border-radius: 50px;
    margin-right: 7px;
}
.blog-grid .card .card-footer .meta-info a {
    color: #74788d;
    font-size: 14px;
}
.blog-grid .card .card-footer .meta-info a:hover {
    color: #5d78ff;
}

.blog-sidebar .widget-search {
    position: relative;
}
.blog-sidebar .widget-search input {
    color: #677294;
    border-radius: 5px;
    background: #fff;
    border: 1px solid #eff0f6;
    padding-left: 30px;
    height: 55px;
    z-index: 0;
}
.blog-sidebar .widget-search span {
    border: 0px;
    border-radius: 0px;
    font-size: 16px;
    color: #282835;
    background: transparent;
    position: absolute;
    right: 20px;
    top: 18px;
    padding: 0px;
    z-index: 1;
    cursor: pointer;
}

.blog-sidebar .widget-recent-post {
    margin-top: 50px;
}
.blog-sidebar .widget-recent-post .post-title {
    font-size: 20px;
    border-bottom: 1px solid #eff0f6;
    padding-bottom: 10px;
    margin-bottom: 30px;
}
.blog-sidebar .widget-recent-post .media img {
    max-width: 60px;
    border-radius: 5px;
}
.blog-sidebar .widget-recent-post .media h5,
.blog-sidebar .widget-recent-post .media .h5 {
    font-size: 16px;
}
.blog-sidebar .widget-recent-post .meta-info a {
    font-size: 14px;
    color: #74788d;
    margin-right: 5px;
}

.blog-sidebar .widget-category {
    margin-top: 50px;
}
.blog-sidebar .widget-category .widget-title {
    font-size: 20px;
    border-bottom: 1px solid #eff0f6;
    padding-bottom: 10px;
    margin-bottom: 30px;
}
.blog-sidebar .widget-category .list-group-item {
    background: #fff;
    border: 0px;
    color: #74788d;
}
.blog-sidebar .widget-category .list-group-item .badge {
    background: #5d78ff;
    border-radius: 5px;
    padding: 5px 7px;
}

.blog-sidebar .widget-tag {
    margin-top: 50px;
}
.blog-sidebar .widget-tag .widget-title {
    font-size: 20px;
    border-bottom: 1px solid #eff0f6;
    padding-bottom: 10px;
    margin-bottom: 30px;
}
.blog-sidebar .widget-tag .tag-group a {
    color: #74788d;
    border: 1px solid #eff0f6;
    border-radius: 3px;
    padding: 5px 15px;
    float: left;
    -webkit-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
    margin: 5px;
    font-size: 14px;
}

.blog-single-details {
    float: left;
    width: 100%;
}

.blog-single-post {
    float: left;
    width: 100%;
    cursor: pointer;
    position: relative;
}
.blog-single-post > ul.post-nfo {
    float: left;
    width: 100%;
    margin-bottom: 7px;
    margin-top: -1px;
}

.post_info > ul.post-nfo {
    float: left;
    width: 100%;
    margin-bottom: 7px;
    margin-top: -1px;
}

.post-nfo li {
    display: inline-block;
    margin-right: 20px;
}
.post-nfo li a {
    display: inline-block;
    color: #74788d;
    font-size: 16px;
    margin-right: 20px;
    margin-right: 0;
}
.post-nfo li i {
    font-size: 16px;
    padding-right: 6px;
    position: relative;
    top: 1px;
}

.pt {
    padding: 105px 0;
}

.blog-single-sec.section-padding {
    padding: 110px 0 110px 0;
}

.blog-single-post > h3,
.blog-single-post > .h3 {
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 23px;
    float: left;
    width: 100%;
}

.post_info > h3,
.post_info > .h3 {
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 23px;
    float: left;
    width: 100%;
}

.blog-img {
    float: left;
    width: 100%;
    margin-bottom: 34px;
    position: relative;
}
.blog-img:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 100%;
    background-color: rgba(127, 142, 233, 0.85);
    opacity: 0;
    visibility: hidden;
}

.agent_img:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 100%;
    background-color: rgba(127, 142, 233, 0.85);
    opacity: 0;
    visibility: hidden;
}

.blog-single-post:hover .blog-img:before,
.agent:hover .agent_img:before {
    opacity: 1;
    visibility: visible;
    left: 0;
    width: 100%;
}

.blog-single-post.single {
    cursor: inherit;
}
.blog-single-post.single .blog-img:before,
.blog-single-post.single .blog-img:after {
    display: none;
}

.blog-img > a img {
    width: 100%;
    float: left;
}

.blog-single-post p {
    float: left;
    width: 100%;
    margin-bottom: 24px;
}

.blog-single-post > blockquote {
    color: #48465b;
    font-size: 17px;
    font-weight: 500;
    line-height: 28px;
    border-left: 3px solid;
    padding: 2px 0 1px 18px;
    float: left;
    width: 100%;
    margin-bottom: 38px;
    margin-top: 13px;
}

.blg-dv {
    float: left;
    width: 100%;
    margin-bottom: 22px;
    margin-top: 8px;
}

.blg-sm {
    float: left;
    width: 100%;
}

.blg-info {
    float: left;
    width: 100%;
    padding-left: 27px;
}

.bg-links {
    float: left;
    width: 100%;
    padding: 0 40px;
    margin-top: 5px;
    margin-bottom: 13px;
}
.bg-links li {
    float: left;
    width: 100%;
    color: #48465b;
    margin-bottom: 17px;
    position: relative;
    padding-left: 19px;
}
.bg-links li:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 5px;
    height: 5px;
    border-radius: 100px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

/* ================ post-share =============== */
.post-share {
    float: left;
    width: 100%;
    padding: 21px 0;
    border-top: 1px solid #eff0f6;
    margin-top: 8px;
    margin-bottom: 27px;
}

.social-links {
    float: left;
}
.social-links li {
    display: inline-block;
    margin-right: 17px;
}
.social-links li a {
    display: inline-block;
    color: #5d78ff;
    font-size: 14px;
}

.post-share > a {
    float: right;
    color: #161616;
    font-size: 14px;
    font-weight: 400;
    margin-top: 3px;
}
.post-share > a i {
    padding-left: 8px;
}

/* ============== cm-info-sec ================ */
.cm-info-sec {
    float: left;
    width: 100%;
    display: table;
    border: 1px solid #eff0f6;
    padding: 29px 24px;
    margin-bottom: 68px;
}

.cm-img {
    display: table-cell;
}
.cm-img > img {
    float: left;
}

.cm-info {
    display: table-cell;
    width: 100%;
    vertical-align: top;
    padding-left: 19px;
    position: relative;
    top: -1px;
}
.cm-info h3,
.cm-info .h3 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
}
.cm-info > p {
    line-height: 24px;
    margin-bottom: 12px;
}

/* ================= comment-section ================  */
.comment-section {
    float: left;
    width: 100%;
    margin-top: 7px;
}

.p-title {
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 30px;
}

.comment-section .cm-info-sec {
    padding: 0;
    border: 0;
    margin: 0;
}

.comment-section .cm-info {
    padding-top: 7px;
}
.comment-section .cm-info > h4,
.comment-section .cm-info > .h4 {
    color: #74788d;
    font-size: 14px;
    font-weight: 400;
}
.comment-section .cm-info h3,
.comment-section .cm-info .h3 {
    margin-bottom: 3px;
}

.comment-section > ul {
    float: left;
    width: 100%;
}
.comment-section > ul > li {
    float: left;
    width: 100%;
    position: relative;
    margin-bottom: 32px;
}

.comment-section ul li p {
    float: left;
    width: 100%;
    margin-top: 13px;
}

.cm-reply {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    position: absolute;
    top: 7px;
    right: 0;
    text-transform: uppercase;
    letter-spacing: 1px;
}

/* ================ post-comment-sec =============== */
.post-comment-sec {
    float: left;
    width: 100%;
    margin-top: 22px;
}
.post-comment-sec .p-title {
    margin-bottom: 18px;
}
.post-comment-sec > form {
    float: left;
    width: 100%;
}
.post-comment-sec .col-lg-4 {
    padding: 0 8px;
}

.buy-sell-widget .nav-tabs {
    margin-bottom: 30px;
    border: 0px;
}
.buy-sell-widget .nav-tabs .nav-item {
    width: 50%;
    text-align: center;
}
.buy-sell-widget .nav-tabs .nav-item .nav-link {
    padding: 10px 10px;
    font-size: 18px;
    text-transform: uppercase;
    border: 0px;
    font-weight: 500;
    border-radius: 5px;
    background: transparent;
    border: 1px solid #eff0f6;
    margin: 0px 15px;
}
.buy-sell-widget .nav-tabs .nav-item .nav-link.active {
    background: #5d78ff;
    color: white;
}

@media only screen and (max-width: 767px) {
    .buyer-seller .d-flex .buyer-info,
    .buyer-seller .d-flex .seller-info {
        padding: 10px;
    }
    .buyer-seller .d-flex .buyer-info img,
    .buyer-seller .d-flex .seller-info img {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .buyer-seller .d-flex {
        justify-content: flex-start;
    }
}

@media only screen and (max-width: 575px) {
    .buyer-seller .d-flex {
        flex-direction: column;
    }
    .buyer-seller .d-flex .seller-info {
        text-align: left !important;
    }
}

#accordion-faq {
    margin-top: -15px;
    box-shadow: none;
}
#accordion-faq .card {
    margin-bottom: 0px;
    border: 0px solid #eff0f6;
    box-shadow: none;
}
#accordion-faq .card .card-header {
    border: 0px;
    padding: 15px 0px 10px;
}
#accordion-faq .card .card-body {
    padding: 0px 0px 10px;
}

.intro-video-play {
    background-color: #fff;
    background-size: cover;
    background-position: 0 0;
    min-height: 363px;
    margin: 0 auto;
    position: relative;
    border-radius: 5px;
    z-index: 0;
    margin-bottom: 30px;
    box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.1);
}
.intro-video-play::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #5d78ff;
    opacity: 0.45;
    border-radius: 5px;
    z-index: -1;
}

.play-btn {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}
.play-btn a {
    background: #5d78ff;
    width: 75px;
    height: 75px;
    border-radius: 100px;
    padding: 27px 0;
    text-align: center;
    cursor: pointer;
}
.play-btn a i {
    color: #fff;
    font-size: 20px;
}

.apps-download {
    margin-bottom: 20px;
    border-radius: 5px;
    position: relative;
    padding: 0px 20px;
}
.apps-download ::before {
    background-color: #3a3361;
    background-size: cover;
    background-position: center;
    position: absolute;
    z-index: -1;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 5px;
    opacity: 0.15;
}
.apps-download ::after {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #5d78ff;
    opacity: 0.3;
    z-index: -1;
    border-radius: 5px;
}

.apps-download-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 504px;
}
.apps-download-content h4,
.apps-download-content .h4 {
    padding: 20px;
    color: #fff;
    text-align: center;
    line-height: 1.3;
}
.apps-download-content .btn {
    min-width: 130px;
}
.apps-download-content .btn-primary {
    background: #2a4eff;
}

.transaction-table td {
    padding: 1.25rem 0.75rem;
}

.transaction-table i.cc {
    font-size: 22px;
    margin-right: 10px;
}

.transaction-table .sold-thumb {
    background: #e50202;
    color: #fff;
    border-radius: 50px;
    width: 30px;
    height: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.transaction-table .sold-thumb i {
    font-weight: 700;
}

.transaction-table .buy-thumb {
    background: #10d876;
    color: #fff;
    border-radius: 15px;
    width: 30px;
    height: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.transaction-table .buy-thumb i {
    font-weight: 700;
}

.transaction-table .badge {
    color: #fff;
}

.info-list ul li {
    margin-bottom: 7px;
    color: #74788d;
    font-weight: 500;
    padding: 12px 0;
}
.info-list ul li:hover,
.info-list ul li:focus {
    color: #5d78ff;
}
.info-list ul li i {
    margin-right: 15px;
    color: #5d78ff;
    font-weight: bold;
    font-size: 24px;
}

@media only screen and (max-width: 767px) {
    .sub-menu ul {
        display: inline-block !important;
    }
}

.sub-menu ul li a {
    padding: 0px 12px;
    display: flex;
    align-items: center;
    color: #74788d;
}
.sub-menu ul li a:hover,
.sub-menu ul li a:focus,
.sub-menu ul li a:active,
.sub-menu ul li a.active {
    color: #5d78ff;
    background-color: transparent;
}

.sub-menu ul li i {
    font-weight: bold;
    margin-right: 7px;
    font-size: 20px;
}

.sub-menu ul li span {
    font-weight: 400;
}

.phone_verify {
    margin-top: 50px;
    border-top: 1px solid #eff0f6;
    padding-top: 30px;
}

.phone_verified {
    display: flex;
    align-items: center;
}
.phone_verified h5,
.phone_verified .h5 {
    margin-bottom: 0;
    margin-right: 30px;
    color: #74788d;
}
.phone_verified h5 span,
.phone_verified .h5 span {
    color: #5d78ff;
    padding: 9px 10px;
    border-radius: 50px;
    margin-right: 7px;
    display: inline-block;
    font-size: 18px;
    font-weight: bold;
    border: 1px solid #eff0f6;
    height: 40px;
    width: 40px;
    display: inline-block;
}
.phone_verified .verified,
.phone_verified .not-verify {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
}
.phone_verified .verified span,
.phone_verified .not-verify span {
    background: #10d876;
    color: #fff;
    padding: 10px;
    border-radius: 50px;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    margin-right: 15px;
    font-weight: bold;
    font-size: 12px;
}
.phone_verified .not-verify span {
    background: #e50202;
}

.id_card {
    padding: 20px;
    border: 1px solid #eff0f6;
    border-radius: 5px;
    margin: 20px 0px;
}

.price-grid .card {
    border: 1px solid #eff0f6;
}
.price-grid .card .card-header {
    padding: 15px 15px 0;
    border: 0px;
}
.price-grid .card .card-header .media {
    align-items: center;
}
.price-grid .card .card-header .media-body {
    color: #48465b;
}
.price-grid .card .card-header i {
    margin-right: 10px;
    font-size: 30px;
}
.price-grid .card .card-header p {
    font-size: 14px;
}
.price-grid .card .card-body {
    padding: 15px 15px 0px !important;
}
.price-grid .card .card-body h3,
.price-grid .card .card-body .h3 {
    font-size: 20px;
    font-weight: 500;
}

.coin-search {
    padding-bottom: 70px;
}
.coin-search .search-box form {
    display: flex;
}
.coin-search .search-box form .btn {
    margin-left: 15px;
    padding: 8px 30px;
}

.sort-view {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
}
.sort-view .search-timeline a {
    color: #48465b;
    font-size: 18px;
    display: inline-block;
    margin-right: 15px;
    font-weight: 500;
}
.sort-view .search-timeline a:hover,
.sort-view .search-timeline a:focus,
.sort-view .search-timeline a:active,
.sort-view .search-timeline a.active {
    color: #5d78ff;
}
.sort-view .coin-view .nav {
    margin-bottom: 0px;
}
.sort-view .coin-view .nav .nav-item:last-child .nav-link {
    margin-right: 0px;
}
.sort-view .coin-view .nav .nav-item .nav-link {
    background: rgba(0, 0, 0, 0.15);
    color: #5d78ff;
    margin-right: 10px;
}
.sort-view .coin-view .nav .nav-item .nav-link:hover,
.sort-view .coin-view .nav .nav-item .nav-link:focus,
.sort-view .coin-view .nav .nav-item .nav-link:active,
.sort-view .coin-view .nav .nav-item .nav-link.active {
    background: #5d78ff;
    color: #fff;
}

.terms_condition {
    position: relative;
}

.terms_condition-content {
    padding: 80px 0px 80px;
}

.terms_condition-text {
    margin-bottom: 45px;
}
.terms_condition-text:last-child {
    margin-bottom: 0;
}
.terms_condition-text h3,
.terms_condition-text .h3 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 30px;
}
.terms_condition-text p {
    font-size: 16px;
    line-height: 30px;
    display: inline-block;
}
.terms_condition-text li {
    display: flex;
}
.terms_condition-text li i {
    line-height: 30px;
    margin-right: 15px;
    font-size: 13px;
    color: #5d78ff;
}

.wallet-feature {
    border-top: 1px solid #eff0f6;
}
.wallet-feature .wallet-feature-content {
    margin-bottom: 50px;
    text-align: center;
}
.wallet-feature .wallet-feature-content i {
    font-size: 40px;
    margin-bottom: 20px;
    color: #5d78ff;
}
.wallet-feature .wallet-feature-content h4,
.wallet-feature .wallet-feature-content .h4 {
    max-width: 200px;
    margin: 0 auto;
}

.all-coin {
    border-bottom: 1px solid #eff0f6;
}

.all-coins {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.all-coins span {
    font-size: 48px;
    width: 100px;
    height: 100px;
    border: 1px solid #eff0f6;
    border-radius: 100%;
    text-align: center;
    margin: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.all-coin-content {
    padding: 75px 0 0;
    text-align: center;
}
.all-coin-content h3,
.all-coin-content .h3 {
    margin-bottom: 15px;
}

.join-team {
    border-bottom: 1px solid #eff0f6;
}

.interested-join {
    border-top: 1px solid #eff0f6;
    background: #2a4eff;
}
.interested-join h2,
.interested-join .h2 {
    color: #fff;
    margin-bottom: 15px;
}
.interested-join p {
    color: #fff;
}
.interested-join .btn {
    padding: 7px 20px;
    margin-top: 30px;
}

.career .career-content-img img {
    border-radius: 5px;
}

.career .career-content h3,
.career .career-content .h3 {
    font-weight: 500;
    margin-bottom: 15px;
}

.career .career-content p {
    line-height: 1.75;
}

.helpdesk-search {
    background: rgba(0, 0, 0, 0.15);
}
.helpdesk-search .helpdesk-search-content {
    text-align: center;
}
.helpdesk-search .helpdesk-form form {
    display: flex;
}
.helpdesk-search .helpdesk-form .btn {
    margin-left: 20px;
    padding: 6px 30px;
}

.help-category .help-cat {
    text-align: center;
    margin-bottom: 50px;
}
.help-category .help-cat span {
    font-size: 48px;
    color: #5d78ff;
}
.help-category .help-cat h4,
.help-category .help-cat .h4 {
    font-size: 20px;
    margin-top: 15px;
}

.help-category .sub-cat {
    display: inline-block;
    padding: 20px 30px;
    background: rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    text-align: center;
    margin-bottom: 30px;
}
.help-category .sub-cat h5,
.help-category .sub-cat .h5 {
    color: #5d78ff;
    margin-bottom: 0px;
}

.troubleshooting-content h4,
.troubleshooting-content .h4 {
    border-bottom: 1px solid #eff0f6;
    padding-bottom: 20px;
    margin-top: 50px;
}

.troubleshooting-content ul {
    margin-top: 20px;
}
.troubleshooting-content ul li a {
    color: #74788d;
    display: inline-block;
    padding-bottom: 10px;
}
.troubleshooting-content ul li a:hover,
.troubleshooting-content ul li a:focus,
.troubleshooting-content ul li a:active,
.troubleshooting-content ul li a.active {
    color: #5d78ff;
}

.ticket-box-content {
    background: rgba(0, 0, 0, 0.15);
    text-align: center;
    padding: 50px 15px;
    border-radius: 5px;
}
.ticket-box-content p {
    padding: 10px 0 20px;
    margin-bottom: 0px;
    font-size: 18px;
}
.ticket-box-content .btn {
    min-width: 150px;
    font-weight: 500;
}

.header {
    padding: 20px 0px;
    position: fixed;
    top: 0;
    left: 100px;
    right: 0;
    z-index: 02; }
.header.bg-primary {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15) !important; }
@media only screen and (max-width: 767px) {
    .header {
        left: 0;
        padding: 10px 0px; } }
.header .brand-logo {
    margin-top: 0px;
    padding-right: 20px;
    border-radius: 5px; }
@media only screen and (max-width: 767px) {
    .header .brand-logo {
        display: block; }
    .header .brand-logo span {
        display: none; } }
.header.landing {
    padding: 20px 0px;
    left: 0px; }
.header.landing a {
    display: flex;
    align-items: center;
    justify-content: center; }
.header.landing .brand-logo {
    display: block; }
.header.landing .brand-logo img {
    margin-right: 10px; }
.header.landing .brand-logo span {
    display: block;
    top: 0; }

.header-content,
.header-left,
.header-right {
    display: flex;
    justify-content: space-between;
    align-items: center; }
.header.fixed {
    padding: 10px 0px;
    box-shadow: 0 0.05rem 0.01rem rgba(75, 75, 90, 0.075);
    border-color: transparent;
}
.header .navigation .navbar {
    background-color: transparent !important;
    padding: 0px;
}
.header .navigation .navbar .collapse {
    justify-content: flex-end;
}
.header .navigation .navbar ul {
    align-items: center;
}
@media only screen and (max-width: 991px) {
    .header .navigation .navbar ul {
        margin-top: 15px;
    }
}
.header .navigation .navbar ul > li {
    display: inline-block;
    padding: 7px;
    width: 100%;
}
@media only screen and (max-width: 991px) {
    .header .navigation .navbar ul > li {
        border-bottom: 1px solid #eff0f6;
        margin: 0 15px;
        padding: 7px 15px;
    }
}
.header .navigation .navbar ul > li > a {
    font-size: 16px;
    font-weight: 500;
    color: #48465b;
}
.header .navigation .navbar ul > li > a :hover,
.header .navigation .navbar ul > li > a:focus,
.header .navigation .navbar ul > li > a.active {
    color: #5d78ff;
}
@media only screen and (max-width: 991px) {
    .header .navigation .navbar ul > li > a::after {
        position: absolute;
        right: 15px;
        top: 25px;
    }
}
@media only screen and (min-width: 991px) {
    .header .navigation .navbar ul .dropdown > a {
        position: relative;
        transition: all 0.2s ease-in-out;
    }
    .header .navigation .navbar ul .dropdown-menu {
        background: rgba(0, 0, 0, 0.15);
        padding: 15px 0;
        display: block;
        opacity: 0;
        visibility: hidden;
        z-index: 1030;
        transition: all 0.2s ease-in-out;
        box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.1);
        border: 0px;
        margin-top: 10px;
        z-index: 998;
        min-width: 10rem;
    }
    .header .navigation .navbar ul .dropdown-menu a {
        display: inline-block;
        color: #74788d;
    }
    .header .navigation .navbar ul .dropdown-menu a:hover,
    .header .navigation .navbar ul .dropdown-menu a:focus,
    .header .navigation .navbar ul .dropdown-menu a.active {
        background: rgba(0, 0, 0, 0.1);
        color: #fff;
    }
    .header .navigation .navbar ul li.dropdown:hover a::before {
        opacity: 1;
        bottom: -9px;
    }
    .header .navigation .navbar ul li.dropdown:hover .dropdown-menu {
        opacity: 1;
        visibility: visible;
        margin-top: 0px;
    }
}
@media only screen and (max-width: 991px) {
    .header .navigation .navbar ul .dropdown-menu {
        border: 0px;
        padding: 0px;
        background: rgba(0, 0, 0, 0.15);
    }
    .header .navigation .navbar ul .dropdown-menu a {
        border-bottom: 1px solid #eff0f6;
        padding: 15px;
        color: #74788d;
    }
    .header .navigation .navbar ul .dropdown-menu a :last-child {
        border: 0px;
    }
    .header .navigation .navbar ul .dropdown-menu a i,
    .header .navigation .navbar ul .dropdown-menu a h6,
    .header .navigation .navbar ul .dropdown-menu a .h6 {
        color: #5d78ff;
    }
    .header .navigation .navbar ul .dropdown-menu a p {
        color: #74788d;
        margin-bottom: 0;
    }
}

.signin-btn .btn {
    padding: 7px 15px !important;
    min-width: 85px !important;
}

@media only screen and (max-width: 991px) {
    .signin-btn {
        position: absolute;
        right: 73px;
        top: 3px;
    }
}

.header.dashboard {
    padding: 5px 10px;
    box-shadow: 0 10px 30px 0 rgba(82, 63, 105, 0.08);
}
.header.dashboard .navbar {
    padding: 0px;
}

.header .navbar-brand {
    display: flex;
    align-items: center;
}
.header .navbar-brand img {
    max-width: 115px;
}
.header .navbar-brand span {
    color: #282a3c;
    font-weight: 500;
    margin-left: 20px;
    font-size: 24px;
}

.header_auth .btn {
    margin-left: 10px;
}
@media only screen and (max-width: 991px) {
    .header_auth .btn {
        padding: 7px;
        min-width: 110px;
    }
}
@media only screen and (max-width: 991px) {
    .header_auth .btn {
        display: none;
    }
}

.navbar-toggler {
    background: rgba(255, 255, 255, 0.2);
    min-width: auto;
}

@media only screen and (max-width: 991px) {
    .navbar-nav {
        margin-top: 10px;
        border-radius: 5px;
    }
}

.navbar-nav .nav-item .nav-link {
    color: #fff;
    font-weight: 500;
    opacity: 0.75;
}
.navbar-nav .nav-item .nav-link:hover,
.navbar-nav .nav-item .nav-link:focus,
.navbar-nav .nav-item .nav-link.active {
    opacity: 1;
}

.language {
    margin-right: 20px;
}
.language .icon {
    cursor: pointer;
}
.language .icon i {
    margin-right: 7px;
}
.language .dropdown-menu {
    border: 0px;
    padding: 5px 0px;
    margin: 0px;
    top: 42px;
    min-width: 100px;
    box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.15);
    border-radius: 5px;
    background-color: #fff;
}
.language .dropdown-menu a {
    padding: 5px 20px;
}

.profile_log {
    cursor: pointer;
}
.profile_log .user {
    display: flex;
    align-items: center;
}
.profile_log .user .thumb {
    height: 32px;
    width: 32px;
    border-radius: 50px;
    border: 1px solid #5d78ff;
    display: inline-block;
    margin-right: 7px;
    background: #5d78ff;
    color: #fff;
    text-align: center;
    padding: 3px 0;
}
.profile_log .user .thumb i {
    font-size: 18px;
    line-height: 1.3;
}
.profile_log .user .arrow i {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    margin-top: 6px;
    display: inline-block;
}
@media only screen and (max-width: 1199px) {
    .profile_log .user .arrow {
        display: none;
    }
}
.profile_log .dropdown-menu {
    border: 0px;
    padding: 0px;
    margin: 0px;
    top: 47px;
    box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.15);
    border-radius: 5px;
    background-color: #fff;
    min-width: 240px;
}
.profile_log .dropdown-menu .user-email {
    padding: 20px 20px 10px;
}
.profile_log .dropdown-menu .user-email h6,
.profile_log .dropdown-menu .user-email .h6 {
    margin-bottom: 0px;
}
.profile_log .dropdown-menu .user-balance {
    display: flex;
    justify-content: space-around;
    margin-bottom: 15px;
}
.profile_log .dropdown-menu .user-balance p {
    margin-bottom: 0px;
    font-weight: 500;
    color: #48465b;
}
.profile_log .dropdown-menu .dropdown-item {
    padding: 5px 20px;
    border-top: 1px solid #eff0f6;
    font-size: 14px;
    color: #74788d;
    font-weight: 400;
    display: flex;
    align-items: center;
}
.profile_log .dropdown-menu .dropdown-item:first-child {
    border: 0px;
}
.profile_log .dropdown-menu .dropdown-item.logout {
    color: #e50202;
}
.profile_log .dropdown-menu .dropdown-item.logout i {
    color: #e50202;
}
.profile_log .dropdown-menu .dropdown-item i {
    margin-right: 10px;
    font-size: 22px;
    color: #5d78ff;
    font-weight: bold;
}
.profile_log .dropdown-menu .dropdown-item:hover,
.profile_log .dropdown-menu .dropdown-item:focus,
.profile_log .dropdown-menu .dropdown-item.active {
    background-color: transparent;
}

.page-title {
    padding: 30px 0;
}
.page-title.dashboard {
    background: transparent;
    margin-left: 75px;
    padding: 20px 0 0px;
}
.page-title .page-title-content p {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0px;
}
.page-title .page-title-content p span {
    font-weight: 500;
    color: #5d78ff;
}

.breadcrumbs {
    display: flex;
    justify-content: flex-end;
}
@media only screen and (max-width: 575px) {
    .breadcrumbs {
        justify-content: flex-start;
    }
}
.breadcrumbs li a {
    color: #aeaed5;
    display: inline-block;
    margin-left: 15px;
    font-size: 14px;
}
.breadcrumbs li:first-child a {
    margin-left: 0px;
}
.breadcrumbs li.active a {
    color: #5d78ff;
}

.sidebar {
    background: #fff;
    position: fixed;
    height: calc(100% - 44px);
    width: 80px;
    left: 0;
    text-align: left;
    float: left;
    margin-left: 0px;
    padding-left: 0px;
    z-index: 3;
    top: 0px;
    bottom: 0px;
    border-radius: 0px;
    border-right: 1px solid #eff2f7; }
@media only screen and (max-width: 767px) {
    .sidebar {
        top: auto;
        bottom: 0;
        width: 100%;
        height: 50px;
        left: 0;
        border-radius: 0px; } }

.menu-dash {
    display: flex;
    margin-left: 0px;
    left: 0;
    transform: translateX(-20%);
    justify-content: space-around;
    align-items: flex-start;
    margin-top: 100px; }
@media only screen and (max-width: 767px) {
    .menu-dash {
        margin-top: 0px; } }
@media only screen and (max-width: 767px) {
    .menu-dash ul {
        display: flex;
        justify-content: space-around;
        align-items: center; } }
.menu-dash ul li {
    text-align: center; }
.menu-dash ul li a {
    text-align: center;
    display: inline-block;
    margin-bottom: 20px;
    border-radius: 9px; }
.menu-dash ul li a:hover, .menu-dash ul li a:focus, .menu-dash ul li a:active {
    color: #1e2e50;
    opacity: 1; }
.menu-dash ul li a:hover i, .menu-dash ul li a:focus i, .menu-dash ul li a:active i {
    color: #1e2e50;
    opacity: 1; }
.menu-dash ul li a.active i {
    color: #613de6;
    opacity: 1; }
.menu-dash ul li a i {
    color: #7184ad;
    font-size: 24px; }
.menu-dash ul li.logout {
    position: absolute;
    bottom: 50px;
    left: 0;
    right: 0; }
@media only screen and (max-width: 767px) {
    .menu-dash ul li.logout {
        position: relative;
        bottom: 0;
        left: auto;
        right: auto; } }

.bottom {
    background: #1b2a4e;
}
.bottom .bottom-logo p {
    line-height: 30px;
    font-weight: 400;
}
@media only screen and (max-width: 991px) {
    .bottom .bottom-widget {
        margin-bottom: 30px;
    }
}
.bottom .bottom-widget .widget-title {
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 20px;
}
.bottom .bottom-widget ul li a {
    color: #74788d;
    font-weight: 400;
    margin-bottom: 10px;
    display: inline-block;
}

.footer {
    border-top: 1px solid #223461;
    background: #1b2a4e;
    padding: 10px 0px;
}
.footer.dashboard {
    background: #fff;
    border-color: #eff0f6;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
}
.footer .copyright p {
    margin: 0;
    font-size: 14px;
    color: #7f88a6;
}
.footer .copyright p a {
    color: #5d78ff;
    font-weight: 500;
}
@media only screen and (max-width: 575px) {
    .footer .copyright p {
        text-align: center;
    }
}
.footer .footer-social {
    text-align: right;
}
@media only screen and (max-width: 575px) {
    .footer .footer-social {
        text-align: center;
    }
}
.footer .footer-social li {
    display: inline-block;
}
.footer .footer-social li a {
    color: #7f88a6;
    padding: 0px 10px;
}

.sidebar-right {
    display: block;
    right: -15.625rem;
    position: fixed;
    top: 0;
    width: 15.625rem;
    background-color: rgba(0, 0, 0, 0.75);
    padding: 15px;
    margin-top: 5rem;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
    padding-bottom: 1.875rem;
    box-shadow: -2px 3px 10px 0px rgba(119, 119, 119, 0.1);
}
.sidebar-right .sidebar-right-trigger {
    position: absolute;
    z-index: 9;
    top: 4.75rem;
    right: 100%;
    background-color: #5d78ff;
    color: #fff;
    display: inline-block;
    height: 2.125rem;
    width: 2.125rem;
    text-align: center;
    font-size: 1.3125rem;
    line-height: 2.2rem;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.sidebar-right .sidebar-right-trigger:hover {
    color: #fff;
}
.sidebar-right.show {
    right: 0;
    z-index: 999;
}
.sidebar-right .admin-settings p {
    margin-bottom: 0.3125rem;
}
.sidebar-right .admin-settings input[type="radio"] {
    display: none;
}
.sidebar-right .admin-settings input[type="radio"] + label {
    display: inline-block;
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin: 3px 8px;
    transition: all 0.1s ease;
    border-radius: 100px;
}
.sidebar-right .admin-settings input[type="radio"]:checked + label {
    position: relative;
}
.sidebar-right .admin-settings input[type="radio"]:checked + label::after {
    height: 12px;
    width: 12px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    content: "";
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 100px;
}
.sidebar-right #nav_header_color_1 + label {
    background-color: #10d078;
}
.sidebar-right #nav_header_color_2 + label {
    background-color: #ff0096;
}
.sidebar-right #nav_header_color_3 + label {
    background-color: #002eff;
}
.sidebar-right #nav_header_color_4 + label {
    background-color: #ff2f00;
}
.sidebar-right #nav_header_color_5 + label {
    background-color: #00ccd3;
}
.sidebar-right #nav_header_color_1 + label {
    border: 1px solid #eff0f6;
}
.sidebar-right #header_color_1 + label {
    background-color: #fff;
}
.sidebar-right #header_color_2 + label {
    background-color: #131722;
}
.sidebar-right #header_color_3 + label {
    background-color: #2d2b55;
}
.sidebar-right #header_color_4 + label {
    background-color: #30243d;
}
.sidebar-right #header_color_5 + label {
    background-color: #193549;
}
.sidebar-right #header_color_1 + label {
    border: 1px solid #eff0f6;
}

@keyframes bounce {
    0% {
        transform: translateX(-8%);
        -webkit-transform: translateX(-8%);
    }
    50% {
        transform: translateX(8%);
        -webkit-transform: translateX(8%);
    }
    100% {
        transform: translateX(-8%);
        -webkit-transform: translateX(-8%);
    }
}

@-webkit-keyframes bounce {
    0% {
        transform: translateX(-8%);
        -webkit-transform: translateX(-8%);
    }
    50% {
        transform: translateX(8%);
        -webkit-transform: translateX(8%);
    }
    100% {
        transform: translateY(-8%);
        -webkit-transform: translateY(-8%);
    }
}

[data-bodybg="color_2"] {
    background-color: #131722 !important;
    color: #7d8db7 !important;
}
[data-bodybg="color_2"] h1,
[data-bodybg="color_2"] .h1,
[data-bodybg="color_2"] h2,
[data-bodybg="color_2"] .h2,
[data-bodybg="color_2"] h3,
[data-bodybg="color_2"] .h3,
[data-bodybg="color_2"] h4,
[data-bodybg="color_2"] .h4,
[data-bodybg="color_2"] h5,
[data-bodybg="color_2"] .h5,
[data-bodybg="color_2"] h6,
[data-bodybg="color_2"] .h6,
[data-bodybg="color_2"] .h1,
[data-bodybg="color_2"] .h2,
[data-bodybg="color_2"] .h3,
[data-bodybg="color_2"] .h4,
[data-bodybg="color_2"] .h5,
[data-bodybg="color_2"] .h6 {
    color: #e0e3ed;
}
[data-bodybg="color_2"] .sidebar,
[data-bodybg="color_2"] .header {
    background: #181e2c;
}
[data-bodybg="color_2"] .card {
    background: #181e2c;
    border: 1px solid #252d43;
}
[data-bodybg="color_2"] .card-header {
    background: #181e2c;
    border-color: #11151f;
}
[data-bodybg="color_2"] .card .card-body {
    background: #181e2c;
}
[data-bodybg="color_2"] .group-2JyOhh7Z {
    background-color: #181e2c !important;
}

[data-bodybg="color_3"] {
    background-color: #2d2b55 !important;
    color: #adabd4 !important;
}
[data-bodybg="color_3"] h1,
[data-bodybg="color_3"] .h1,
[data-bodybg="color_3"] h2,
[data-bodybg="color_3"] .h2,
[data-bodybg="color_3"] h3,
[data-bodybg="color_3"] .h3,
[data-bodybg="color_3"] h4,
[data-bodybg="color_3"] .h4,
[data-bodybg="color_3"] h5,
[data-bodybg="color_3"] .h5,
[data-bodybg="color_3"] h6,
[data-bodybg="color_3"] .h6,
[data-bodybg="color_3"] .h1,
[data-bodybg="color_3"] .h2,
[data-bodybg="color_3"] .h3,
[data-bodybg="color_3"] .h4,
[data-bodybg="color_3"] .h5,
[data-bodybg="color_3"] .h6 {
    color: white;
}
[data-bodybg="color_3"] .sidebar,
[data-bodybg="color_3"] .header {
    background: #32305f;
}
[data-bodybg="color_3"] .card {
    background: #32305f;
    border: 1px solid #3f3c77;
}
[data-bodybg="color_3"] .card-header {
    background: #32305f;
    border-color: #2b2952;
}
[data-bodybg="color_3"] .card .card-body {
    background: #32305f;
}
[data-bodybg="color_3"] .group-2JyOhh7Z {
    background-color: #32305f !important;
}

[data-bodybg="color_4"] {
    background-color: #30243d !important;
    color: #af9cc4 !important;
}
[data-bodybg="color_4"] h1,
[data-bodybg="color_4"] .h1,
[data-bodybg="color_4"] h2,
[data-bodybg="color_4"] .h2,
[data-bodybg="color_4"] h3,
[data-bodybg="color_4"] .h3,
[data-bodybg="color_4"] h4,
[data-bodybg="color_4"] .h4,
[data-bodybg="color_4"] h5,
[data-bodybg="color_4"] .h5,
[data-bodybg="color_4"] h6,
[data-bodybg="color_4"] .h6,
[data-bodybg="color_4"] .h1,
[data-bodybg="color_4"] .h2,
[data-bodybg="color_4"] .h3,
[data-bodybg="color_4"] .h4,
[data-bodybg="color_4"] .h5,
[data-bodybg="color_4"] .h6 {
    color: #fcfcfd;
}
[data-bodybg="color_4"] .sidebar,
[data-bodybg="color_4"] .header {
    background: #382a47;
}
[data-bodybg="color_4"] .card {
    background: #382a47;
    border: 1px solid #49375d;
}
[data-bodybg="color_4"] .card-header {
    background: #382a47;
    border-color: #2d223a;
}
[data-bodybg="color_4"] .card .card-body {
    background: #382a47;
}
[data-bodybg="color_4"] .group-2JyOhh7Z {
    background-color: #382a47 !important;
}

[data-bodybg="color_5"] {
    background-color: #193549 !important;
    color: #8ab7d7 !important;
}
[data-bodybg="color_5"] h1,
[data-bodybg="color_5"] .h1,
[data-bodybg="color_5"] h2,
[data-bodybg="color_5"] .h2,
[data-bodybg="color_5"] h3,
[data-bodybg="color_5"] .h3,
[data-bodybg="color_5"] h4,
[data-bodybg="color_5"] .h4,
[data-bodybg="color_5"] h5,
[data-bodybg="color_5"] .h5,
[data-bodybg="color_5"] h6,
[data-bodybg="color_5"] .h6,
[data-bodybg="color_5"] .h1,
[data-bodybg="color_5"] .h2,
[data-bodybg="color_5"] .h3,
[data-bodybg="color_5"] .h4,
[data-bodybg="color_5"] .h5,
[data-bodybg="color_5"] .h6 {
    color: #fcfdfe;
}
[data-bodybg="color_5"] .sidebar,
[data-bodybg="color_5"] .header {
    background: #1d3d54;
}
[data-bodybg="color_5"] .card {
    background: #1d3d54;
    border: 1px solid #26516f;
}
[data-bodybg="color_5"] .card-header {
    background: #1d3d54;
    border-color: #183245;
}
[data-bodybg="color_5"] .card .card-body {
    background: #1d3d54;
}
[data-bodybg="color_5"] .group-2JyOhh7Z {
    background-color: #1d3d54 !important;
}

[data-primary-color="color_2"] .btn-primary {
    background-color: #ff0096;
    border-color: #ff0096;
}
[data-primary-color="color_2"] .btn-primary:hover,
[data-primary-color="color_2"] .btn-primary:focus,
[data-primary-color="color_2"] .btn-primary:active,
[data-primary-color="color_2"] .btn-primary.active {
    background-color: #cc0078;
    border-color: #cc0078;
    box-shadow: none;
}
[data-primary-color="color_2"]
.btn-primary:not(:disabled):not(.disabled):active,
[data-primary-color="color_2"]
.btn-primary:not(:disabled):not(.disabled).active {
    background-color: #cc0078;
    border-color: #cc0078;
    box-shadow: none;
}

[data-primary-color="color_2"] .btn-outline-primary {
    background-color: transparent;
    color: #ff0096;
    border-color: #ff0096;
}
[data-primary-color="color_2"] .btn-outline-primary:hover,
[data-primary-color="color_2"] .btn-outline-primary:focus,
[data-primary-color="color_2"] .btn-outline-primary:active,
[data-primary-color="color_2"] .btn-outline-primary.active {
    background-color: #cc0078;
    border-color: #cc0078;
    box-shadow: none;
    color: #fff;
}
[data-primary-color="color_2"]
.btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary-color="color_2"]
.btn-outline-primary:not(:disabled):not(.disabled).active {
    background-color: #cc0078;
    border-color: #cc0078;
    box-shadow: none;
    color: #fff;
}

[data-primary-color="color_2"] .sidebar {
    background: #e60087;
}

[data-primary-color="color_2"] i.la,
[data-primary-color="color_2"] i.fa,
[data-primary-color="color_2"] .text-primary {
    color: #ff0096 !important;
}

[data-primary-color="color_2"] .bg-primary {
    background-color: #ff0096 !important;
}

[data-primary-color="color_2"] .owl-next,
[data-primary-color="color_2"] .owl-prev {
    background-color: #cc0078 !important;
}
[data-primary-color="color_2"] .owl-next i,
[data-primary-color="color_2"] .owl-prev i {
    color: #fff !important;
}

[data-primary-color="color_2"] .intro-form button {
    background: #ff0096;
    color: #fff !important;
}
[data-primary-color="color_2"] .intro-form button i {
    color: #fff !important;
}

[data-primary-color="color_2"] .intro-form-exchange .btn i {
    color: #fff !important;
}

[data-primary-color="color_2"] .product-feature-box i {
    color: #fff !important;
}

[data-primary-color="color_2"] .trust-content {
    border-color: #ff0096;
}

[data-primary-color="color_2"] .footer a {
    color: #ff0096 !important;
}

[data-primary-color="color_2"] .sort-view .search-timeline a:hover,
[data-primary-color="color_2"] .sort-view .search-timeline a:focus,
[data-primary-color="color_2"] .sort-view .search-timeline a:active,
[data-primary-color="color_2"] .sort-view .search-timeline a.active {
    color: #ff0096;
}

[data-primary-color="color_2"] .sort-view .coin-view .nav .nav-item .nav-link {
    color: #fff;
}

[data-primary-color="color_2"]
.sort-view
.coin-view
.nav
.nav-item
.nav-link:hover,
[data-primary-color="color_2"]
.sort-view
.coin-view
.nav
.nav-item
.nav-link:focus,
[data-primary-color="color_2"]
.sort-view
.coin-view
.nav
.nav-item
.nav-link:active,
[data-primary-color="color_2"]
.sort-view
.coin-view
.nav
.nav-item
.nav-link.active {
    background: #ff0096;
}

[data-primary-color="color_2"] .badge {
    background: #ff0096 !important;
    color: #fff !important;
}

[data-primary-color="color_2"] .intro-video-play::after {
    background: #ff0096;
}

[data-primary-color="color_2"] .menu ul li a,
[data-primary-color="color_2"] .menu ul li a i,
[data-primary-color="color_2"] .menu ul li a.active i {
    color: #fff !important;
}

[data-primary-color="color_2"] .page-title .page-title-content p {
    color: #ff0096;
}

[data-primary-color="color_2"]
.profile_chart.card
.card-header
.duration-option
a:hover,
[data-primary-color="color_2"]
.profile_chart.card
.card-header
.duration-option
a:focus,
[data-primary-color="color_2"]
.profile_chart.card
.card-header
.duration-option
a.active {
    background: #ff0096;
}

[data-primary-color="color_2"] .profile_log .user .thumb {
    background: #ff0096;
    color: #fff !important;
}
[data-primary-color="color_2"] .profile_log .user .thumb i {
    color: #fff !important;
}

[data-primary-color="color_2"] .trade-app-content .card-body a {
    color: #ff0096;
}

[data-primary-color="color_2"] .buy-sell-widget .nav-tabs .nav-item .nav-link {
    color: #ff0096;
}

[data-primary-color="color_2"]
.buy-sell-widget
.nav-tabs
.nav-item
.nav-link.active {
    background: #ff0096;
    color: white;
}

[data-primary-color="color_2"] .social-icons a {
    background: #ff0096;
    color: white !important;
}
[data-primary-color="color_2"] .social-icons a i {
    color: white !important;
}

[data-primary-color="color_2"] .settings_menu ul li a {
    color: #ff0096;
}

[data-primary-color="color_2"] .toggle-checkbox:checked + .toggle-switch {
    background: #ff0096;
}

[data-primary-color="color_2"] .file-upload-wrapper:before {
    background: #ff0096;
}

[data-primary-color="color_2"] .sidebar-right .sidebar-right-trigger {
    background-color: #ff0096 !important;
    color: #fff !important;
}
[data-primary-color="color_2"] .sidebar-right .sidebar-right-trigger i {
    color: #fff !important;
}

[data-primary-color="color_2"] .form-control:focus,
[data-primary-color="color_2"] .form-control:active,
[data-primary-color="color_2"] .form-control.active {
    border-color: #ff0096;
}

[data-primary-color="color_3"] .btn-primary {
    background-color: #002eff;
    border-color: #002eff;
}
[data-primary-color="color_3"] .btn-primary:hover,
[data-primary-color="color_3"] .btn-primary:focus,
[data-primary-color="color_3"] .btn-primary:active,
[data-primary-color="color_3"] .btn-primary.active {
    background-color: #0025cc;
    border-color: #0025cc;
    box-shadow: none;
}
[data-primary-color="color_3"]
.btn-primary:not(:disabled):not(.disabled):active,
[data-primary-color="color_3"]
.btn-primary:not(:disabled):not(.disabled).active {
    background-color: #0025cc;
    border-color: #0025cc;
    box-shadow: none;
}

[data-primary-color="color_3"] .btn-outline-primary {
    background-color: transparent;
    color: #002eff;
    border-color: #002eff;
}
[data-primary-color="color_3"] .btn-outline-primary:hover,
[data-primary-color="color_3"] .btn-outline-primary:focus,
[data-primary-color="color_3"] .btn-outline-primary:active,
[data-primary-color="color_3"] .btn-outline-primary.active {
    background-color: #0025cc;
    border-color: #0025cc;
    box-shadow: none;
    color: #fff;
}
[data-primary-color="color_3"]
.btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary-color="color_3"]
.btn-outline-primary:not(:disabled):not(.disabled).active {
    background-color: #0025cc;
    border-color: #0025cc;
    box-shadow: none;
    color: #fff;
}

[data-primary-color="color_3"] .sidebar {
    background: #0029e6;
}

[data-primary-color="color_3"] i.la,
[data-primary-color="color_3"] i.fa,
[data-primary-color="color_3"] .text-primary {
    color: #002eff !important;
}

[data-primary-color="color_3"] .bg-primary {
    background-color: #002eff !important;
}

[data-primary-color="color_3"] .owl-next,
[data-primary-color="color_3"] .owl-prev {
    background-color: #0025cc !important;
}
[data-primary-color="color_3"] .owl-next i,
[data-primary-color="color_3"] .owl-prev i {
    color: #fff !important;
}

[data-primary-color="color_3"] .intro-form button {
    background: #002eff;
    color: #fff !important;
}
[data-primary-color="color_3"] .intro-form button i {
    color: #fff !important;
}

[data-primary-color="color_3"] .intro-form-exchange .btn i {
    color: #fff !important;
}

[data-primary-color="color_3"] .product-feature-box i {
    color: #fff !important;
}

[data-primary-color="color_3"] .trust-content {
    border-color: #002eff;
}

[data-primary-color="color_3"] .footer a {
    color: #002eff !important;
}

[data-primary-color="color_3"] .sort-view .search-timeline a:hover,
[data-primary-color="color_3"] .sort-view .search-timeline a:focus,
[data-primary-color="color_3"] .sort-view .search-timeline a:active,
[data-primary-color="color_3"] .sort-view .search-timeline a.active {
    color: #002eff;
}

[data-primary-color="color_3"] .sort-view .coin-view .nav .nav-item .nav-link {
    color: #fff;
}

[data-primary-color="color_3"]
.sort-view
.coin-view
.nav
.nav-item
.nav-link:hover,
[data-primary-color="color_3"]
.sort-view
.coin-view
.nav
.nav-item
.nav-link:focus,
[data-primary-color="color_3"]
.sort-view
.coin-view
.nav
.nav-item
.nav-link:active,
[data-primary-color="color_3"]
.sort-view
.coin-view
.nav
.nav-item
.nav-link.active {
    background: #002eff;
}

[data-primary-color="color_3"] .badge {
    background: #002eff !important;
    color: #fff !important;
}

[data-primary-color="color_3"] .intro-video-play::after {
    background: #002eff;
}

[data-primary-color="color_3"] .menu ul li a,
[data-primary-color="color_3"] .menu ul li a i,
[data-primary-color="color_3"] .menu ul li a.active i {
    color: #fff !important;
}

[data-primary-color="color_3"] .page-title .page-title-content p {
    color: #002eff;
}

[data-primary-color="color_3"]
.profile_chart.card
.card-header
.duration-option
a:hover,
[data-primary-color="color_3"]
.profile_chart.card
.card-header
.duration-option
a:focus,
[data-primary-color="color_3"]
.profile_chart.card
.card-header
.duration-option
a.active {
    background: #002eff;
}

[data-primary-color="color_3"] .profile_log .user .thumb {
    background: #002eff;
    color: #fff !important;
}
[data-primary-color="color_3"] .profile_log .user .thumb i {
    color: #fff !important;
}

[data-primary-color="color_3"] .trade-app-content .card-body a {
    color: #002eff;
}

[data-primary-color="color_3"] .buy-sell-widget .nav-tabs .nav-item .nav-link {
    color: #002eff;
}

[data-primary-color="color_3"]
.buy-sell-widget
.nav-tabs
.nav-item
.nav-link.active {
    background: #002eff;
    color: white;
}

[data-primary-color="color_3"] .social-icons a {
    background: #002eff;
    color: white !important;
}
[data-primary-color="color_3"] .social-icons a i {
    color: white !important;
}

[data-primary-color="color_3"] .settings_menu ul li a {
    color: #002eff;
}

[data-primary-color="color_3"] .toggle-checkbox:checked + .toggle-switch {
    background: #002eff;
}

[data-primary-color="color_3"] .file-upload-wrapper:before {
    background: #002eff;
}

[data-primary-color="color_3"] .sidebar-right .sidebar-right-trigger {
    background-color: #002eff !important;
    color: #fff !important;
}
[data-primary-color="color_3"] .sidebar-right .sidebar-right-trigger i {
    color: #fff !important;
}

[data-primary-color="color_3"] .form-control:focus,
[data-primary-color="color_3"] .form-control:active,
[data-primary-color="color_3"] .form-control.active {
    border-color: #002eff;
}

[data-primary-color="color_4"] .btn-primary {
    background-color: #ff2f00;
    border-color: #ff2f00;
}
[data-primary-color="color_4"] .btn-primary:hover,
[data-primary-color="color_4"] .btn-primary:focus,
[data-primary-color="color_4"] .btn-primary:active,
[data-primary-color="color_4"] .btn-primary.active {
    background-color: #cc2600;
    border-color: #cc2600;
    box-shadow: none;
}
[data-primary-color="color_4"]
.btn-primary:not(:disabled):not(.disabled):active,
[data-primary-color="color_4"]
.btn-primary:not(:disabled):not(.disabled).active {
    background-color: #cc2600;
    border-color: #cc2600;
    box-shadow: none;
}

[data-primary-color="color_4"] .btn-outline-primary {
    background-color: transparent;
    color: #ff2f00;
    border-color: #ff2f00;
}
[data-primary-color="color_4"] .btn-outline-primary:hover,
[data-primary-color="color_4"] .btn-outline-primary:focus,
[data-primary-color="color_4"] .btn-outline-primary:active,
[data-primary-color="color_4"] .btn-outline-primary.active {
    background-color: #cc2600;
    border-color: #cc2600;
    box-shadow: none;
    color: #fff;
}
[data-primary-color="color_4"]
.btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary-color="color_4"]
.btn-outline-primary:not(:disabled):not(.disabled).active {
    background-color: #cc2600;
    border-color: #cc2600;
    box-shadow: none;
    color: #fff;
}

[data-primary-color="color_4"] .sidebar {
    background: #e62a00;
}

[data-primary-color="color_4"] i.la,
[data-primary-color="color_4"] i.fa,
[data-primary-color="color_4"] .text-primary {
    color: #ff2f00 !important;
}

[data-primary-color="color_4"] .bg-primary {
    background-color: #ff2f00 !important;
}

[data-primary-color="color_4"] .owl-next,
[data-primary-color="color_4"] .owl-prev {
    background-color: #cc2600 !important;
}
[data-primary-color="color_4"] .owl-next i,
[data-primary-color="color_4"] .owl-prev i {
    color: #fff !important;
}

[data-primary-color="color_4"] .intro-form button {
    background: #ff2f00;
    color: #fff !important;
}
[data-primary-color="color_4"] .intro-form button i {
    color: #fff !important;
}

[data-primary-color="color_4"] .intro-form-exchange .btn i {
    color: #fff !important;
}

[data-primary-color="color_4"] .product-feature-box i {
    color: #fff !important;
}

[data-primary-color="color_4"] .trust-content {
    border-color: #ff2f00;
}

[data-primary-color="color_4"] .footer a {
    color: #ff2f00 !important;
}

[data-primary-color="color_4"] .sort-view .search-timeline a:hover,
[data-primary-color="color_4"] .sort-view .search-timeline a:focus,
[data-primary-color="color_4"] .sort-view .search-timeline a:active,
[data-primary-color="color_4"] .sort-view .search-timeline a.active {
    color: #ff2f00;
}

[data-primary-color="color_4"] .sort-view .coin-view .nav .nav-item .nav-link {
    color: #fff;
}

[data-primary-color="color_4"]
.sort-view
.coin-view
.nav
.nav-item
.nav-link:hover,
[data-primary-color="color_4"]
.sort-view
.coin-view
.nav
.nav-item
.nav-link:focus,
[data-primary-color="color_4"]
.sort-view
.coin-view
.nav
.nav-item
.nav-link:active,
[data-primary-color="color_4"]
.sort-view
.coin-view
.nav
.nav-item
.nav-link.active {
    background: #ff2f00;
}

[data-primary-color="color_4"] .badge {
    background: #ff2f00 !important;
    color: #fff !important;
}

[data-primary-color="color_4"] .intro-video-play::after {
    background: #ff2f00;
}

[data-primary-color="color_4"] .menu ul li a,
[data-primary-color="color_4"] .menu ul li a i,
[data-primary-color="color_4"] .menu ul li a.active i {
    color: #fff !important;
}

[data-primary-color="color_4"] .page-title .page-title-content p {
    color: #ff2f00;
}

[data-primary-color="color_4"]
.profile_chart.card
.card-header
.duration-option
a:hover,
[data-primary-color="color_4"]
.profile_chart.card
.card-header
.duration-option
a:focus,
[data-primary-color="color_4"]
.profile_chart.card
.card-header
.duration-option
a.active {
    background: #ff2f00;
}

[data-primary-color="color_4"] .profile_log .user .thumb {
    background: #ff2f00;
    color: #fff !important;
}
[data-primary-color="color_4"] .profile_log .user .thumb i {
    color: #fff !important;
}

[data-primary-color="color_4"] .trade-app-content .card-body a {
    color: #ff2f00;
}

[data-primary-color="color_4"] .buy-sell-widget .nav-tabs .nav-item .nav-link {
    color: #ff2f00;
}

[data-primary-color="color_4"]
.buy-sell-widget
.nav-tabs
.nav-item
.nav-link.active {
    background: #ff2f00;
    color: white;
}

[data-primary-color="color_4"] .social-icons a {
    background: #ff2f00;
    color: white !important;
}
[data-primary-color="color_4"] .social-icons a i {
    color: white !important;
}

[data-primary-color="color_4"] .settings_menu ul li a {
    color: #ff2f00;
}

[data-primary-color="color_4"] .toggle-checkbox:checked + .toggle-switch {
    background: #ff2f00;
}

[data-primary-color="color_4"] .file-upload-wrapper:before {
    background: #ff2f00;
}

[data-primary-color="color_4"] .sidebar-right .sidebar-right-trigger {
    background-color: #ff2f00 !important;
    color: #fff !important;
}
[data-primary-color="color_4"] .sidebar-right .sidebar-right-trigger i {
    color: #fff !important;
}

[data-primary-color="color_4"] .form-control:focus,
[data-primary-color="color_4"] .form-control:active,
[data-primary-color="color_4"] .form-control.active {
    border-color: #ff2f00;
}

[data-primary-color="color_5"] .btn-primary {
    background-color: #00ccd3;
    border-color: #00ccd3;
}
[data-primary-color="color_5"] .btn-primary:hover,
[data-primary-color="color_5"] .btn-primary:focus,
[data-primary-color="color_5"] .btn-primary:active,
[data-primary-color="color_5"] .btn-primary.active {
    background-color: #009ba0;
    border-color: #009ba0;
    box-shadow: none;
}
[data-primary-color="color_5"]
.btn-primary:not(:disabled):not(.disabled):active,
[data-primary-color="color_5"]
.btn-primary:not(:disabled):not(.disabled).active {
    background-color: #009ba0;
    border-color: #009ba0;
    box-shadow: none;
}

[data-primary-color="color_5"] .btn-outline-primary {
    background-color: transparent;
    color: #00ccd3;
    border-color: #00ccd3;
}
[data-primary-color="color_5"] .btn-outline-primary:hover,
[data-primary-color="color_5"] .btn-outline-primary:focus,
[data-primary-color="color_5"] .btn-outline-primary:active,
[data-primary-color="color_5"] .btn-outline-primary.active {
    background-color: #009ba0;
    border-color: #009ba0;
    box-shadow: none;
    color: #fff;
}
[data-primary-color="color_5"]
.btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary-color="color_5"]
.btn-outline-primary:not(:disabled):not(.disabled).active {
    background-color: #009ba0;
    border-color: #009ba0;
    box-shadow: none;
    color: #fff;
}

[data-primary-color="color_5"] .sidebar {
    background: #00b3ba;
}

[data-primary-color="color_5"] i.la,
[data-primary-color="color_5"] i.fa,
[data-primary-color="color_5"] .text-primary {
    color: #00ccd3 !important;
}

[data-primary-color="color_5"] .bg-primary {
    background-color: #00ccd3 !important;
}

[data-primary-color="color_5"] .owl-next,
[data-primary-color="color_5"] .owl-prev {
    background-color: #009ba0 !important;
}
[data-primary-color="color_5"] .owl-next i,
[data-primary-color="color_5"] .owl-prev i {
    color: #fff !important;
}

[data-primary-color="color_5"] .intro-form button {
    background: #00ccd3;
    color: #fff !important;
}
[data-primary-color="color_5"] .intro-form button i {
    color: #fff !important;
}

[data-primary-color="color_5"] .intro-form-exchange .btn i {
    color: #fff !important;
}

[data-primary-color="color_5"] .product-feature-box i {
    color: #fff !important;
}

[data-primary-color="color_5"] .trust-content {
    border-color: #00ccd3;
}

[data-primary-color="color_5"] .footer a {
    color: #00ccd3 !important;
}

[data-primary-color="color_5"] .sort-view .search-timeline a:hover,
[data-primary-color="color_5"] .sort-view .search-timeline a:focus,
[data-primary-color="color_5"] .sort-view .search-timeline a:active,
[data-primary-color="color_5"] .sort-view .search-timeline a.active {
    color: #00ccd3;
}

[data-primary-color="color_5"] .sort-view .coin-view .nav .nav-item .nav-link {
    color: #fff;
}

[data-primary-color="color_5"]
.sort-view
.coin-view
.nav
.nav-item
.nav-link:hover,
[data-primary-color="color_5"]
.sort-view
.coin-view
.nav
.nav-item
.nav-link:focus,
[data-primary-color="color_5"]
.sort-view
.coin-view
.nav
.nav-item
.nav-link:active,
[data-primary-color="color_5"]
.sort-view
.coin-view
.nav
.nav-item
.nav-link.active {
    background: #00ccd3;
}

[data-primary-color="color_5"] .badge {
    background: #00ccd3 !important;
    color: #fff !important;
}

[data-primary-color="color_5"] .intro-video-play::after {
    background: #00ccd3;
}

[data-primary-color="color_5"] .menu ul li a,
[data-primary-color="color_5"] .menu ul li a i,
[data-primary-color="color_5"] .menu ul li a.active i {
    color: #fff !important;
}

[data-primary-color="color_5"] .page-title .page-title-content p {
    color: #00ccd3;
}

[data-primary-color="color_5"]
.profile_chart.card
.card-header
.duration-option
a:hover,
[data-primary-color="color_5"]
.profile_chart.card
.card-header
.duration-option
a:focus,
[data-primary-color="color_5"]
.profile_chart.card
.card-header
.duration-option
a.active {
    background: #00ccd3;
}

[data-primary-color="color_5"] .profile_log .user .thumb {
    background: #00ccd3;
    color: #fff !important;
}
[data-primary-color="color_5"] .profile_log .user .thumb i {
    color: #fff !important;
}

[data-primary-color="color_5"] .trade-app-content .card-body a {
    color: #00ccd3;
}

[data-primary-color="color_5"] .buy-sell-widget .nav-tabs .nav-item .nav-link {
    color: #00ccd3;
}

[data-primary-color="color_5"]
.buy-sell-widget
.nav-tabs
.nav-item
.nav-link.active {
    background: #00ccd3;
    color: white;
}

[data-primary-color="color_5"] .social-icons a {
    background: #00ccd3;
    color: white !important;
}
[data-primary-color="color_5"] .social-icons a i {
    color: white !important;
}

[data-primary-color="color_5"] .settings_menu ul li a {
    color: #00ccd3;
}

[data-primary-color="color_5"] .toggle-checkbox:checked + .toggle-switch {
    background: #00ccd3;
}

[data-primary-color="color_5"] .file-upload-wrapper:before {
    background: #00ccd3;
}

[data-primary-color="color_5"] .sidebar-right .sidebar-right-trigger {
    background-color: #00ccd3 !important;
    color: #fff !important;
}
[data-primary-color="color_5"] .sidebar-right .sidebar-right-trigger i {
    color: #fff !important;
}

[data-primary-color="color_5"] .form-control:focus,
[data-primary-color="color_5"] .form-control:active,
[data-primary-color="color_5"] .form-control.active {
    border-color: #00ccd3;
}

[data-font="opensans"] {
    font-family: "Open Sans", sans-serif;
}

[data-font="nunito"] {
    font-family: "Nunito", sans-serif;
}

.demo_img {
    text-align: center;
    margin-bottom: 60px;
}
.demo_img .img-wrap {
    overflow: hidden;
    margin-bottom: 15px;
    box-shadow: 0px 36px 48px rgba(31, 66, 135, 0.04);
    background: #fff;
    padding: 10px;
    border-radius: 5px;
}
.demo_img img {
    border-radius: 5px;
}

.intro-demo_img {
    box-shadow: 0px 36px 48px rgba(31, 66, 135, 0.04);
}
.intro-demo_img img {
    border-radius: 5px;
}

.form-check-input:focus {
    border-color: #7367f0;
}
.form-check-input:checked, .form-check-input[type=checkbox]:indeterminate {
    background-color: #7367f0;
    border-color: #7367f0;
}

.custom-option {
    padding-left: 0;
    border: 1px solid #dbdade;
    border-radius: 0.375rem;
}
.custom-option:hover {
    border: 1px solid #c9c8ce;
}
.custom-option.custom-option-image {
    border-width: 1px !important;
}
.custom-option.custom-option-image .custom-option-body img {
    border-radius: 0.375rem;
}
.custom-option .custom-option-content {
    cursor: pointer;
    width: 100%;
}

.custom-option-basic .custom-option-content {
    padding: 1.07em;
    padding-left: 2.77em;
}
.custom-option-basic .custom-option-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0.25rem;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
    cursor: default;
    opacity: 0.5;
}

.form-check-label {
    cursor: pointer;
}
